import React from "react";
// import Review from "../../components/front/Review";

const Dummy = () => {
  return (
    <>
    {/* <Header></Header> */}
     
   <div className="about-us-content">
        <div className="container">
          <div className="row term-card align-items-center mb-4">
            sadasdsadasdsa
          </div>
        </div>
      </div> 
    
      {/* <Review /> */}
      {/* <Footer></Footer> */}
    </>
  );
};

export default Dummy;