import React from 'react'

const FrontLoader = () => {
  return (
   <div className='front-loading-box'>
     <div
  className="loading-spinner"
  style={{
    background: "url('/assets/img/loaderLogo.webp') center no-repeat",
  }}
></div>
   </div>
  )
}

export default FrontLoader